
import Vue from "vue";

import {
  payPlanChangeRequestMapState,
  payPlanChangeRequestMapActions,
  payPlanChangeRequestMapGetters
} from "@/store/modules/payplanchangerequest";
import { companiesMapGetters } from "@/store/modules/companies";
import { authMapGetters } from "@/store/modules/auth";
import { printSingleChangeRequest } from "@/helpers/downloadLossForm";

import { object } from "dot-object";
import { toTitleCaseFromCamelCase } from "@/helpers";

interface IPayPlan {
  errorMessage: string;
  successMessage: string;
  resourceNotFound: boolean;
  didInit: boolean;
  payPlanChangeRequestData: any;
  pageDataInitialized: boolean;
}

export default Vue.extend({
  name: "PayPlanChangeRequestDetailsViewLegacy",

  data(): IPayPlan {
    return {
      errorMessage: "",
      successMessage: "",
      resourceNotFound: false,
      didInit: false,
      payPlanChangeRequestData: {},
      pageDataInitialized: false
    };
  },
  components: {
    // DataView
    ViewChangeRequestInsurerDetails: () =>
      import(
        "@/components/ChangeRequests/ViewChangeRequestInsurerDetailsLegacy.vue"
      ),
    ViewChangeRequestPolicyDetails: () =>
      import(
        "@/components/ChangeRequests/ViewChangeRequestPolicyDetailsLegacy.vue"
      ),
    DisplayAttachment: () =>
      import("@/components/DataView/DisplayAttachment.vue")
  },
  computed: {
    ...authMapGetters(["getCurrentUser"]),
    payPlanChangeRequestId(): string {
      return this.$route.params.payplanchangerequest_id;
    },
    ...payPlanChangeRequestMapState(["makingApiRequest", "editing"]),
    ...payPlanChangeRequestMapGetters(["getPayPlanChangeRequestById"]),
    original(): any {
      return this.getPayPlanChangeRequestById(this.payPlanChangeRequestId);
    },
    ...companiesMapGetters(["companies"]),
    agentApplicationID(): any {
      return this.$route.params.agentApplicationID;
    },

    topButtonSecondaryItems(): {
      text: string;
      key: string;
      subItems: any[];
    } | null {
      return {
        text: "Actions",
        key: "actions",
        subItems: [
          { title: "Back", command: "back" },
          { title: "Print", command: "print" }
        ]
      };
    }
  },
  created() {
    this.initializePageData();
  },
  destroyed() {},
  methods: {
    ...payPlanChangeRequestMapActions(["getPayPlanChangeRequest"]),
    getValue(data: object, property: string): string {
      return data ? `${data}.${property}` : "";
    },
    // function to get the property of the object
    getProperty(data: object, property: keyof typeof object): string {
      return data ? data[property] : "";
    },

    onToolbarItemSelected(action: string): void {
      switch (action) {
        case "back":
          this.$router.push(`/payplanchangerequests`).catch(() => {});
          break;
        case "print":
          this.printCallback();
          break;
        default:
          break;
      }
    },

    async initializePageData() {
      try {
        await this.getPayPlanChangeRequest(this.payPlanChangeRequestId);
        const { data, ...rootFields } = this.editing as any;
        this.payPlanChangeRequestData = {
          ...rootFields,
          ...data
        };
        this.pageDataInitialized = true;
      } catch (error) {
        this.resourceNotFound = true;
        this.$bugSnagClient.notify(error);
      }
    },

    //function to change a string from camelCase to Title Case separated by a space
    toTitleCaseFromCamelCase(str: string): string {
      return toTitleCaseFromCamelCase(str);
    },
    toTitleCaseFromKebabCase(value: string): string {
      let str = "";
      switch (value) {
        case "annual":
          str = "Annual";
          break;
        case "semiAnnual":
          str = "Semi Annual";
          break;
        case "4pay":
          str = "4 Pay";
          break;
        case "9pay":
          str = "9 Pay";
          break;
        default:
          break;
      }
      return str;
    },
    async printCallback() {
      const policyPrefix =
        this.editing &&
        this.editing.data &&
        this.editing.data.policyInfo &&
        this.editing.data.policyInfo.policyPrefix
          ? this.editing.data.policyInfo.policyPrefix
          : undefined;
      await printSingleChangeRequest.bind(this)({
        changeRequestIds: this.editing?._id,
        policyPrefix,
        printAction: "payplanchangerequest/printPayPlanChangeRequest"
      });
    }
  }
});
